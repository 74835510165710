<template>
  <Layout>
    <div class="createdModules">
      <div class="container">
        <modal-upload :data="data"></modal-upload>
        <modal-categoria></modal-categoria>
        <div
          :class="{ editModuleClass: editModuleModal, hide: !editModuleModal }"
        >
          <div class="contentAdd" data-anima="top">
            <svg
              @click="closeEditModule"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="title" v-if="!createnewmodule">Editar Módulo</div>
            <div class="title" v-else>Criar Módulo</div>
            <br />
            <div class="spaceInputs">
              <b-form-group label="Nome do Módulo" label-for="name-module">
                <b-form-input
                  v-model="editNameModule"
                  placeholder="Nome do Módulo"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="Capa do módulo" label-for="capa">
                <p class="textDimenssion">
                  Tamanho recomendado para capa vertical: 528 x 710px ou proporcional.<br>
                  Tamanho recomendado para capa horizontal: 528 x 316px ou proporcional.
                </p>

                <div
                  class="btn-openModal"
                  @click="showModalUploadCapa"
                  v-if="midiacapamodule === '' || midiacapamodule === null"
                >
                  <p class="text">Clique e faça o Upload da Capa do Módulo</p>
                </div>
                <div
                  class="btn-openModalPreview"
                  @click="showModalUploadCapa"
                  v-else
                >
                  <img
                    class="imagePreviewCapa"
                    :src="midiacapamoduleurl"
                    :alt="midiacapamodule"
                  />
                </div>
                <div class="space margin-none">
                  <button
                    class="btn-salvar remove"
                    v-if="midiacapamodule !== '' && midiacapamodule !== null"
                    @click="
                      (midiacapamodule = null), (midiacapamoduleurl = null)
                    "
                  >
                    Remover Capa
                  </button>
                </div>
              </b-form-group>
              <b-form-group label="Módulo Condicional" label-for="capa">
                <toggle-button :sync="true" v-model="condicionalModule" />
              </b-form-group>
              <b-form-group
                v-if="condicionalModule"
                label="Selecione a Turma que terá o Módulo"
                label-for="capa"
              >
                <multiselect
                  noOptions="Nenhuma Turma Disponivel"
                  placeholder="Turmas"
                  selectedLabel=""
                  :deselectLabel="''"
                  tagPlaceholder=""
                  :selectLabel="''"
                  track-by="id"
                  :multiple="true"
                  :taggable="false"
                  :custom-label="customLabel"
                  v-model="courseClassModule"
                  :options="AllClass"
                >
                </multiselect>
              </b-form-group>
            </div>
            <div class="space">
              <button
                v-if="!createnewmodule"
                class="btn-salvar"
                @click="salvarEditarModule"
              >
                Salvar
              </button>
              <button v-else class="btn-salvar" @click="addNewModule">
                Salvar
              </button>
            </div>
          </div>
        </div>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5">
            <div class="navigate">
              <div class="title">
                {{ nameCourse }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  @change="enventSwitchStatus"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5" data-anima="top">
            <div class="containerWizzard">
              <div class="title d-flex justify-content-center w-100">
                Gerenciamento de Módulo
                <IconSax name="copy" class="ml-2" size="1.7rem" />
              </div>
            </div>
            <div class="containerWizzardInputs" id="step10" v-if="!loading">
              <draggable
                :list="AllModules"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                @end="onEnd"
              >
                <div
                  class="spaceModuleCard"
                  v-for="(modules, index) in AllModules"
                  :key="modules.id"
                  :data-id="modules.id"
                >
                  <div class="actionModule" :id="'firstmodule' + index">
                    <div class="actionModule">
                      <div
                        class="bannerModule"
                        :id="'modulename' + index"
                        @click="editAulas(modules.id)"
                        v-if="modules.cover !== null"
                      >
                        <img
                          class="imgModule"
                          width="50"
                          :src="modules.cover.cdn_url"
                          alt="imagem"
                        />
                      </div>
                      <div
                        class="titleModule d-flex align-items-center"
                        :id="'modulename' + index"
                        @click="editAulas(modules.id)"
                      >
                        <span class="titleBreak">{{ modules.title }}</span>
                      </div>
                    </div>
                    <div class="actions" style="
                        width: max-content;
                        display: flex;
                        align-items: center;
                    ">
                      <IconSax name="edit" size="1.2rem" />
                      <b-dropdown
                        id="dropdown-dropright"
                        dropright
                        text=""
                        class="m-2"
                      >                        
                        <b-dropdown-item @click="editModule(modules)"
                          >Editar Módulo</b-dropdown-item
                        >
                        <b-dropdown-item @click="editAulas(modules.id)"
                          >Editar Aulas</b-dropdown-item
                        >
                        <b-dropdown-item @click="viewModule(modules.id)"
                          >Ver Módulo</b-dropdown-item
                        >
                        <b-dropdown-item @click="deleteModule(modules.id)"
                          >Deletar Módulo</b-dropdown-item
                        >
                      </b-dropdown>
                      <button
                        id="viewModuleLesson"
                        class="viewModuleLesson d-flex"
                        @click="viewModule(modules.id)"
                      >
                        <p class="m-0">Ver Módulo</p>
                        <IconSax name="redo" class="ml-3" />
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="spaceInputs">
                <b-form-group class="paddingNewModule">
                  <div
                    class="btn-newModule"
                    @click="openModalToaddNew()"
                    id="step11"
                  >
                    <p class="text">Adicionar mais um Módulo</p>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";
import Slidebar from "@/components/Slidebar.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import ModalCategoria from "@/components/ModalCategoria.vue";
import CategoryService from "@/services/resources/CategoryService";
import CourseService from "@/services/resources/CourseService";
import { ToggleButton } from "vue-js-toggle-button";
const serviceCourse = CourseService.build();
const serviceCategory = CategoryService.build();
import draggable from "vuedraggable";
import notify from "@/services/libs/notificacao";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    Multiselect,
    Slidebar,
    ModalUpload,
    ModalCategoria,
    ToggleButton,
    draggable,
  },
  data() {
    return {
      condicionalModule: false,
      courseClassModule: [],
      nameCourse: "",
      nameModulo: "",
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      step: 0,
      AllCategory: 1,
      data: "introducao",
      midiaintroducao: "",
      midiacapa: "",
      midiaaulas: "",
      module_id_edit: "",
      AllModules: [],
      totalModules: "",
      nameAula: "",
      descricaoAula: "",
      AllAulas: "",
      editNameModule: "",
      editModuleModal: false,
      editActualModule: "",
      cursoAtual: "",
      moduleAtual: "",
      myDataVariable: true,
      existParans: true,
      enabled: true,
      dragging: false,
      moduleLiberation: false,
      dataInit: "",
      dataFim: "",
      createnewmodule: false,
      midiacapamodule: null,
      midiacapamoduleurl: null,
      homeVertical: false,
      loading: false,
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    customLabel({ title }) {
      return `${title}`;
    },
    getClass() {
      serviceCourse
        .read(this.cursoAtual + "/class")
        .then((resp) => {
          //console.log("get class", resp);
          this.AllClass = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    viewModule(moduleId) {
      serviceCourse
        .read(this.cursoAtual + "/module/" + moduleId + "/lesson")
        .then((resp) => {
          if (resp.length === 0) {
            notify("erro", "Nenhuma Aula no Módulo!");
            return false;
          }
          let routeData = this.$router.resolve(
            "/curso/" +
              this.cursoAtual +
              "/modulo/" +
              moduleId +
              "/aula/" +
              resp[0].id
          );
          window.open(routeData.href, "_blank");
        });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus,
      };
      //console.log(data);
      this.loading = true;
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          this.loading = false;
          //console.log("update course status", resp);
          this.getCourse();
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    onEnd: function(e) {
      //console.log("event", e);

      var array = [];
      var lessons = document.querySelectorAll(".spaceModuleCard");
      for (let i = 0; i < lessons.length; i++) {
        const lessonId = lessons[i].getAttribute("data-id");
        array.push(parseInt(lessonId));
      }
      let data = {
        id: this.cursoAtual + "/module/order",
        array,
      };

      this.loading = true;
      serviceCourse
        .postIDArray(data)
        .then((resp) => {
          //console.log("order module", resp, array);
          this.getModules();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    editAulas(idModule) {
      this.$router.push(
        "/config_curso/" + this.cursoAtual + "/module/" + idModule + "/lesson"
      );
    },
    salvarEditarModule() {
      //console.log(this.condicionalModule, this.courseClassModule);
      let data = {
        id: this.cursoAtual + "/module/" + this.editActualModule,
        title: this.editNameModule,
        cover: this.midiacapamodule,
        is_conditional: false,
      };

      if (this.condicionalModule) {
        var metas = this.courseClassModule;
        var result = [];
        if (metas.length) {
          metas.forEach((element) => {
            result.push(element.id);
          });
        } else {
          notify("erro", "Selecione uma Turma!");
          return;
        }

        data.course_classes = result;
        data.is_conditional = true;
      } else {
        data.course_classes = [];
        data.is_conditional = false;
      }

      this.loading = true;
      serviceCourse
        .postID(data)
        .then((resp) => {
          //console.log("edit module", resp);
          (this.editNameModule = ""),
            (this.editModuleModal = false),
            (this.editActualModule = ""),
            (this.createnewmodule = true);
          notify("sucesso", "Módulo editado com Sucesso!");
          this.onEnd();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Editar Módulo!");
          this.loading = false;
        });
    },
    editModule(data) {
      //console.log(data);
      this.editActualModule = "";
      this.editNameModule = "";
      this.midiacapamodule = null;
      this.midiacapamoduleurl = null;

      this.condicionalModule = data.is_conditional;
      if (data.course_classes !== null) {
        var myArray = data.course_classes;
        var AllClass = this.AllClass;
        var result = [];
        for (let i = 0; i < myArray.length; i++) {
          const element = myArray[i];
          var filter = AllClass.filter((x) => x.id === myArray[i]);
          result.push(filter[0]);
        }
        //console.log(result);
        this.courseClassModule = result;
      } else {
        this.courseClassModule = [];
      }

      this.editActualModule = data.id;
      this.editNameModule = data.title;
      if (data.cover !== null) {
        this.midiacapamodule = data.cover.id;
        this.midiacapamoduleurl = data.cover.cdn_url;
      }
      this.editModuleModal = true;
      this.createnewmodule = false;
    },
    timeConvert(num) {
      var hours = Math.floor(num / 60);
      var minutes = num % 60;
      return hours + ":" + minutes;
    },
    closeEditModule() {
      this.editModuleModal = false;
      this.createnewmodule = true;
    },
    deleteModule(id) {
      this.loading = true;
      serviceCourse
        .destroy(this.cursoAtual + "/module/" + id)
        .then((resp) => {
          //console.log("delete module", resp);
          this.getModules();
          notify("sucesso", "Módulo deletado com Sucesso!");
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          this.loading = false;
        });
    },
    openModalToaddNew() {
      this.editActualModule = "";
      this.editNameModule = "";
      this.editModuleModal = true;
      this.createnewmodule = true;
    },
    addNewModule(firstName) {
      var dataTitle = this.editNameModule;

      if (dataTitle === "" || dataTitle === null || dataTitle === undefined) {
        dataTitle = firstName;
      }

      let data = {
        id: this.cursoAtual + "/module",
        title: dataTitle,
        cover: this.midiacapamodule,
        is_conditional: false,
      };

      if (this.condicionalModule) {
        var metas = this.courseClassModule;
        var result = [];
        if (metas.length) {
          metas.forEach((element) => {
            result.push(element.id);
          });
        } else {
          notify("erro", "Selecione uma Turma!");
          return;
        }

        data.course_classes = result;
        data.is_conditional = true;
      } else {
        data.course_classes = [];
        data.is_conditional = false;
      }

      this.loading = true;
      serviceCourse
        .postID(data)
        .then((resp) => {
          //console.log("created module", resp);
          (this.editNameModule = ""),
            (this.editModuleModal = false),
            (this.editActualModule = ""),
            notify("sucesso", "Módulo criado com Sucesso!");
          this.getModules();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Criar Módulo!");
          this.loading = false;
        });
    },
    getModules() {
      this.loading = true;
      serviceCourse
        .read(this.cursoAtual + "/module")
        .then((resp) => {
          //console.log("get modules", resp);
          this.AllModules = resp;
          this.totalModules = resp.length;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    openModalCategoria() {
      this.$root.$emit("bv::show::modal", "modal-categoria", "#btnShow");
    },
    showModalUploadIntro() {
      this.data = "introducao";
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadCapa() {
      this.$root.$emit("uploadcapamodulo");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUpload() {
      this.data = "aulas";
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    hideModalUpload() {
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    getCategory() {
      this.loading = true;
      serviceCategory
        .search()
        .then((resp) => {
          //console.log("category", resp);
          this.categoryCourse = resp[0].id;
          this.AllCategory = resp;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getCourse() {
      let data = {
        id: this.cursoAtual,
      };
      this.loading = true;
      serviceCourse
        .read(data)
        .then((resp) => {
          //console.log("get course", resp);
          this.nameCourse = resp.title;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getHomeVertical() {
      serviceMeta
        .search("keys[]=home_vertical")
        .then((resp) => {
          //console.log("meta helper", resp);
          var data = resp.home_vertical;
          if (data === "off" || data === null) {
            this.homeVertical = false;
          } else {
            this.homeVertical = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getCourseModule() {
      let idCourse = this.$route.params.curso;
      let tath = this;
      if (idCourse === null || idCourse === undefined || idCourse === "") {
        this.$router.push("/config_curso");
      } else {
        tath.cursoAtual = idCourse;
        this.loading = true;
        serviceCourse
          .read(tath.cursoAtual + "/module")
          .then((resp) => {
            //console.log("get course idCourse", resp);
            if (resp.length === 0 || resp.length === "0") {
              tath.addNewModule("Módulo 1");
            } else {
              this.getModules();
            }
            this.getCourse();
            this.getClass();
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getCourseModule();
    this.getHomeVertical();
    this.$root.$on("midiaaulas", (data) => {
      this.midiaaulas = parseInt(data);
    });
    this.$root.$on("newuploadcapamodulo", (data) => {
      //console.log(data, "chegou aquiiiiii");
      this.midiacapamodule = data.id;
      this.midiacapamoduleurl = data.url;
    });
    this.$root.$on("stepconcluirmodulo", (data) => {
      this.editAulas(this.AllModules[0].id);
    });
  },
};
</script>
<style lang="scss">
.createdModules {
  .removeLink {
    text-decoration: none;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }

  .editModuleClass {
    position: fixed;
    background: var(--fundomodal);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .contentAdd {
      z-index: 99999;
      position: absolute;
      padding: 30px;
      overflow-y: auto;
      right: 0;
      top: 0;
      width: 450px;
      height: 100%;
      background: var(--backgroundcolor);
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .margin-none {
        margin: 10px 0 !important;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
        .remove {
          background: red !important;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        width: 97%;
        .titleModule{
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
          line-height: 1.5em;
          flex:1;
          .titleBreak{
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
          }

        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: var(--backgroundcolor);
        border: 0.5px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 15px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
    .containerx {
      width: 98%;
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .spaceInputs {
    margin-bottom: 30px;
    .textInfo {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #81858e;
      margin-top: 5px;
    }
    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
    }
    .btn-openModal {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      cursor: pointer;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }
    .gerenciarCat {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      position: absolute;
      right: 25em;
      cursor: pointer;
    }
    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }
    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }
}

.imgModule {
  margin-top: 5px;
  width: 75px;
  margin-right: 10px;
  border-radius: 3px;
}
</style>
